import { ItemSociedad } from '../types';
import { WebEntity } from './types';

const ItemSociedadEntity: WebEntity<ItemSociedad> = {
  name: 'itemSociedad',
  endpoint: 'itemSociedad',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      id: '_id',
      selector: (row) => row._id,
      columnType: 'text',
      omitExport: true,
      omit: true
    },
    {
      name: 'Material',
      id: 'material',
      selector: (row) => row.material,
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Texto Breve Material',
      id: 'textoBreveMaterial',
      selector: (row) => row.textoBreveMaterial,
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Grupo Articulos',
      id: 'grupoArticulos',
      selector: (row) => row.grupoArticulos,
      columnType: 'text'
    },
    {
      name: 'Unidad Medida Base',
      id: 'unidadMedidaBase',
      selector: (row) => row.unidadMedidaBase,
      columnType: 'text'
    },
    {
      name: 'Sociedad',
      id: 'nombreSociedad',
      selector: (row) => row.nombreSociedad,
      columnType: 'text'
    },
    {
      name: 'Tipo Centro',
      id: 'tipoSociedad',
      selector: (row) => row.tipoSociedad,
      columnType: 'text'
    }
  ]
};

export const ITEM_SOCIEDAD_COLUMNS = {
  material: 'Material',
  textoBreveMaterial: 'Texto Breve Material',
  grupoArticulos: 'Grupo Articulos',
  unidadMedidaBase: 'Unidad Medida Base',
  nombreSociedad: 'Sociedad',
  tipoSociedad: 'Tipo Centro'
};

export default ItemSociedadEntity;
