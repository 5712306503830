import React, { useMemo } from 'react';
import { Cobro, TipoUsuarioValues } from '../../types';
import { WebEntity } from './types';
import { dateFormatFn, formatStringNumber } from '../../utils/utils';
import { DownloadFile } from '../utils';
import { AgregarCartaCobranza, AgregarIdCobroCcu } from '../../components/SecondaryButton';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { Alert, Box, Tooltip } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';

const CobroEntity: WebEntity<Cobro> = {
  name: 'cobro',
  endpoint: 'cobros',
  referenceColumn: '_id',
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text',
      enableHiding: false,
      visibleInShowHideMenu: false
    },
    {
      header: 'ID cobro',
      accessorKey: 'cobroId',
      columnType: 'text',
      size: 140
    },
    {
      header: 'OCA',
      accessorKey: 'oca',
      columnType: 'text',
      size: 140
    },
    {
      header: 'ID cobro CCU',
      accessorKey: 'idCobroCcu',
      columnType: 'text',
      size: 160,
      AggregatedCell: ({ row }) => {
        if (row.depth !== 0) return '';

        const CheckAccess = (tipoUsuario: TipoUsuarioValues) => {
          const auth = useSelector((state: AppState) => state.auth);
          const nombreTipoUsuario = useMemo(() => auth.user?.nombreTipoUsuario, [auth.user]);
          return nombreTipoUsuario === tipoUsuario;
        };

        return row.original.idCobroCcu ? (
          row.original.idCobroCcu
        ) : CheckAccess(TipoUsuarioValues.AdminCobros) ? (
          <AgregarIdCobroCcu
            rowData={row.original as Cobro}
            tipoCobro={row.original.nombreTipoDesviacion}
          />
        ) : (
          <Tooltip title='Solo los administradores de cobro pueden modificar este campo'>
            <Alert icon={<BlockIcon fontSize='inherit' />} severity='error'>
              Sin acceso
            </Alert>
          </Tooltip>
        );
      }
    },
    {
      header: 'Carta cobranza',
      accessorKey: 'cartaCobranza',
      columnType: 'text',
      size: 180,
      omitExport: true,
      AggregatedCell: ({ row }) => {
        if (row.depth !== 0) return '';

        const CheckAccess = (tipoUsuario: TipoUsuarioValues) => {
          const auth = useSelector((state: AppState) => state.auth);
          const nombreTipoUsuario = useMemo(() => auth.user?.nombreTipoUsuario, [auth.user]);
          return nombreTipoUsuario === tipoUsuario;
        };

        return row.original.cartaCobranza ? (
          <DownloadFile
            fileName={row.original.cartaCobranza}
            fileType='application/pdf'
            buttonName='Carta cobranza'
          />
        ) : CheckAccess(TipoUsuarioValues.AdminCobros) ? (
          <AgregarCartaCobranza
            rowData={row.original as Cobro}
            tipoCobro={row.original.nombreTipoDesviacion}
          />
        ) : (
          <Tooltip title='Solo los administradores de cobro pueden modificar este campo'>
            <Alert icon={<BlockIcon fontSize='inherit' />} severity='error'>
              Sin acceso
            </Alert>
          </Tooltip>
        );
      }
    },
    {
      header: 'Fecha Cobro',
      accessorKey: 'fechaCobro',
      columnType: 'dateTimeUTC',
      size: 160,
      accessorFn: (row) => dateFormatFn(row.fechaCobro, 'DD/MM/YYYY HH:mm:ss', true),
      AggregatedCell: ({ row }) => {
        if (row.depth !== 0) return '';
        return dateFormatFn(row.original.fechaCobro, 'DD/MM/YYYY HH:mm:ss', true);
      }
    },
    {
      header: 'Detalle',
      accessorKey: 'detalle',
      columnType: 'text',
      size: 140,
      AggregatedCell: ({ row }) => (row.depth === 1 ? row.original.detalle : '')
    },
    {
      header: 'Causal',
      accessorKey: 'nombreCausal',
      columnType: 'text',
      size: 140,
      AggregatedCell: ({ row }) => (row.depth === 1 ? row.original.nombreCausal : '')
    },
    {
      header: 'Evidencia OCA',
      accessorKey: 'evidencia',
      columnType: 'text',
      size: 160,
      omitExport: true,
      AggregatedCell: ({ row }) =>
        row.depth === 1 && row.original.evidencia ? (
          <DownloadFile
            fileName={row.original.evidencia}
            fileType='application/zip'
            buttonName='Evidencia OCA'
          />
        ) : (
          ''
        )
    },
    {
      header: 'Transporte',
      accessorKey: 'nombreTransportista',
      columnType: 'text',
      size: 160,
      AggregatedCell: ({ row }) => (row.depth === 1 ? row.original.nombreTransportista : '')
    },
    {
      header: 'Patente',
      accessorKey: 'patente',
      columnType: 'text',
      size: 140,
      AggregatedCell: ({ row }) => (row.depth === 1 ? row.original.patente : '')
    },
    {
      header: 'Guia',
      accessorKey: 'guia',
      columnType: 'text',
      size: 140,
      AggregatedCell: ({ row }) => (row.depth === 1 ? row.original.guia : '')
    },
    {
      header: 'SKU',
      accessorKey: 'material',
      columnType: 'text',
      size: 140
    },
    {
      header: 'Cantidad de cajas',
      accessorKey: 'cantidadCajas',
      columnType: 'numeric',
      size: 180,
      Cell: ({ row }) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
          {formatStringNumber(row.original.cantidadCajas)}
        </div>
      ),
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Box
              sx={{
                fontWeight: 'bold'
              }}>
              {sum && formatStringNumber(sum?.toString(), 0, 0)}
            </Box>
          </div>
        );
      }
    },
    {
      header: 'Descripcion SKU',
      accessorKey: 'textoBreveMaterial',
      columnType: 'text',
      size: 180
    },
    {
      header: 'CD Origen',
      accessorKey: 'nombreCentroOrigen',
      columnType: 'text',
      size: 140,
      AggregatedCell: ({ row }) => (row.depth === 1 ? row.original.nombreCentroOrigen : '')
    },
    {
      header: 'CD Destino',
      accessorKey: 'nombreCentroDestino',
      columnType: 'text',
      size: 140,
      AggregatedCell: ({ row }) => (row.depth === 1 ? row.original.nombreCentroDestino : '')
    },
    {
      header: 'Fecha OCA',
      accessorKey: 'fechaOca',
      columnType: 'dateUTC',
      size: 160,
      accessorFn: (row) => dateFormatFn(row.fechaOca, 'DD/MM/YYYY', true),
      AggregatedCell: ({ row }) =>
        row.depth === 1 ? dateFormatFn(row.original.fechaOca, 'DD/MM/YYYY HH:mm:ss', true) : ''
    },
    {
      header: 'Fecha ingreso OCA',
      accessorKey: 'fechaOcaIngresada',
      columnType: 'dateTimeUTC',
      size: 200,
      accessorFn: (row) => dateFormatFn(row.fechaOcaIngresada, 'DD/MM/YYYY HH:mm:ss', true),
      AggregatedCell: ({ row }) =>
        row.depth === 1
          ? dateFormatFn(row.original.fechaOcaIngresada, 'DD/MM/YYYY HH:mm:ss', true)
          : ''
    },
    {
      header: 'Fecha objeción por CD Origen',
      accessorKey: 'fechaOcaObjetadaOrigen',
      columnType: 'dateTimeUTC',
      size: 250,
      accessorFn: (row) => dateFormatFn(row.fechaOcaObjetadaOrigen, 'DD/MM/YYYY HH:mm:ss', true),
      AggregatedCell: ({ row }) =>
        row.depth === 1
          ? dateFormatFn(row.original.fechaOcaObjetadaOrigen, 'DD/MM/YYYY HH:mm:ss', true)
          : ''
    },
    {
      header: 'Comentario Objeción por CD Origen',
      accessorKey: 'comentarioObjecionOrigen',
      columnType: 'text',
      size: 280,
      AggregatedCell: ({ row }) => (row.depth === 1 ? row.original.comentarioObjecionOrigen : '')
    },
    {
      header: 'Archivo objeción por CD Origen',
      accessorKey: 'nombreArchivoObjecionOrigen',
      columnType: 'text',
      size: 260,
      omitExport: true,
      AggregatedCell: ({ row }) =>
        row.depth === 1 && row.original.nombreArchivoObjecionOrigen ? (
          <DownloadFile
            fileName={row.original.nombreArchivoObjecionOrigen}
            fileType='application/pdf'
            buttonName='Objecion'
          />
        ) : (
          ''
        )
    },
    {
      header: 'Fecha objeción por EETT',
      accessorKey: 'fechaOcaObjetadaTransportista',
      columnType: 'dateTimeUTC',
      size: 220,
      accessorFn: (row) =>
        dateFormatFn(row.fechaOcaObjetadaTransportista, 'DD/MM/YYYY HH:mm:ss', true),
      AggregatedCell: ({ row }) =>
        row.depth === 1
          ? dateFormatFn(row.original.fechaOcaObjetadaTransportista, 'DD/MM/YYYY HH:mm:ss', true)
          : ''
    },
    {
      header: 'Comentario Objeción por EETT',
      accessorKey: 'comentarioObjecionTransportista',
      columnType: 'text',
      size: 260,
      AggregatedCell: ({ row }) =>
        row.depth === 1 ? row.original.comentarioObjecionTransportista : ''
    },
    {
      header: 'Archivo objeción por EETT',
      accessorKey: 'nombreArchivoObjecionTransportista',
      columnType: 'text',
      size: 240,
      omitExport: true,
      AggregatedCell: ({ row }) =>
        row.depth === 1 && row.original.nombreArchivoObjecionTransportista ? (
          <DownloadFile
            fileName={row.original.nombreArchivoObjecionTransportista}
            fileType='application/pdf'
            buttonName='Objecion'
          />
        ) : (
          ''
        )
    },
    {
      header: 'Comentario Contra Objeción por CD Destino',
      accessorKey: 'comentarioContraObjecionDestino',
      columnType: 'text',
      size: 330,
      AggregatedCell: ({ row }) =>
        row.depth === 1 ? row.original.comentarioContraObjecionDestino : ''
    },
    {
      header: 'Fecha cierre',
      accessorKey: 'fechaOcaCerrada',
      columnType: 'text',
      size: 160,
      accessorFn: (row) => dateFormatFn(row.fechaOcaCerrada, 'DD/MM/YYYY HH:mm:ss', true),
      AggregatedCell: ({ row }) =>
        row.depth === 1
          ? dateFormatFn(row.original.fechaOcaCerrada, 'DD/MM/YYYY HH:mm:ss', true)
          : ''
    },
    {
      header: 'Comentario Administrador',
      accessorKey: 'comentarioAdministrador',
      columnType: 'text',
      size: 230,
      AggregatedCell: ({ row }) => (row.depth === 1 ? row.original.comentarioAdministrador : '')
    },
    {
      header: 'Estado anterior',
      accessorKey: 'estadoOcaAnterior',
      columnType: 'text',
      size: 170,
      AggregatedCell: ({ row }) => (row.depth === 1 ? row.original.estadoOcaAnterior : '')
    }
  ]
};

export default CobroEntity;
