import { Sociedad } from '../types';
import { FieldTypes, WebEntity } from './types';

const SociedadEntity: WebEntity<Sociedad> = {
  name: 'sociedad',
  endpoint: 'sociedad',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      id: '_id',
      selector: (row) => row._id,
      columnType: 'text',
      omitExport: true,
      omit: true
    },
    {
      name: 'Tipo Centro',
      id: 'tipoSociedad',
      selector: (row) => row.tipoSociedad,
      columnType: 'text'
    },
    {
      name: 'Sociedad',
      id: 'nombreSociedad',
      selector: (row) => row.nombreSociedad,
      columnType: 'text'
    }
  ],
  fields: [
    {
      name: 'Tipo Centro',
      selector: 'tipoSociedad',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Sociedad',
      selector: 'nombreSociedad',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'Tipo Centro',
      selector: 'tipoSociedad',
      type: FieldTypes.Text,
      required: true,
      disabled: true
    },
    {
      name: 'Sociedad',
      selector: 'nombreSociedad',
      type: FieldTypes.Text,
      required: true
    }
  ]
};

export default SociedadEntity;
