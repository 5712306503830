import React, { CSSProperties } from 'react';
import GenericIcons from './GenericIcons';

export enum InfoTagSizeValues {
  bg = 'BIG',
  notbg = 'NOT_BG'
}

export type InfoTagSizeValuesType = (typeof InfoTagSizeValues)[keyof typeof InfoTagSizeValues];

type Props = {
  title?: string;
  strongTitle?: string;
  icon?: string;
  typeInfo?: InfoTagSizeValuesType;
};

const Styles: { [key: string]: CSSProperties } = {
  ContainerGenericInfoTag: {
    display: 'flex',
    width: '810px',
    height: '44px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    gap: '5px',
    border: '2px solid #ffb81c',
    background: ' rgba(255, 184, 28, 0.15)',
    marginBottom: '10px'
  },
  ContainerGenericInfoTagBig: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '5px',
    borderRadius: '8px',
    border: '2px solid #ffb81c',
    background: ' rgba(255, 184, 28, 0.15)',
    marginBottom: '10px'
  },
  titleStrongInfoTag: {
    color: 'var(--gray-2, #4f4f4f)',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '900',
    lineHeight: '24px'
  },

  containeriIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '30%'
  },
  notbg: {
    display: ' flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: 'white'
  }
};

const GenericInfoTag = ({ title, strongTitle, icon, typeInfo }: Props) => {
  return (
    <div
      style={
        typeInfo !== InfoTagSizeValues.notbg ? Styles.ContainerGenericInfoTagBig : Styles.notbg
      }>
      <div style={Styles.containeriIcon}>
        {icon ? <GenericIcons icon={icon} imageStyle={{ width: '100%', height: '28px' }} /> : null}
        <strong style={Styles.titleStrongInfoTag}>{strongTitle}</strong>
      </div>
      <p style={{ flex: '1' }}>{title}</p>
    </div>
  );
};

export default GenericInfoTag;
