import { WebEntityName } from '../entities/types';
import {
  BasicEntity,
  Inventario,
  Documento,
  EstadoDocumento,
  TipoUsuario,
  User,
  CentroInventario,
  ReporteZonaCategoriaSKU,
  ReporteVencimiento,
  ReporteInventarioCriticidad,
  ZonasOperador,
  Sku,
  SkuFactor,
  PerfilEnvejecimiento,
  DiasVenta,
  ConteoCriticidadLog,
  InconsistenciaFechaLog,
  TipoActivo,
  Nomenclatura,
  RelacionEnvaseCasillero,
  Activo,
  Comentario,
  MaterialNomenclatura,
  OCA,
  UsuarioTransportista,
  Transportista,
  TipoCausal,
  Cobro,
  Dashboard,
  ItemSociedad,
  Item,
  Sociedad
} from '../types';

/* Auth state */

export type Impersonation = {
  passwordLessToken: string;
  passwordLessUser: User;
};

export type AuthState = {
  token: string | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  user: User | null;
  impersonation: Impersonation | null;
};

export const AUTH_ERROR = 'AUTH_ERROR';
export const IMPERSONATE_CLEAR = 'IMPERSONATE_CLEAR';
export const IMPERSONATE_LOADED = 'IMPERSONATE_LOADED';
export const IMPERSONATE_LOADING = 'IMPERSONATE_LOADING';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const USER_LOADED = 'USER_LOADED';
export const USER_LOADING = 'USER_LOADING';

type AuthErrorAction = {
  type: typeof AUTH_ERROR;
};

type ImpersonateClearAction = {
  type: typeof IMPERSONATE_CLEAR;
};

type ImpersonateLoadedAction = {
  type: typeof IMPERSONATE_LOADED;
  payload: {
    passwordLessToken: string;
    passwordLessUser: User;
  };
};

type ImpersonateLoadingAction = {
  type: typeof IMPERSONATE_LOADING;
};

type LoginFailAction = {
  type: typeof LOGIN_FAIL;
};

type LoginSuccessAction = {
  type: typeof LOGIN_SUCCESS;
  payload: {
    token: string;
    user: User;
  };
};

type LogoutSuccessAction = {
  type: typeof LOGOUT_SUCCESS;
};

type RegisterFailAction = {
  type: typeof REGISTER_FAIL;
};

type RegisterSuccessAction = {
  type: typeof REGISTER_SUCCESS;
  payload: {
    token: string;
    user: User;
  };
};

type UserLoadedAction = {
  type: typeof USER_LOADED;
  payload: User;
};

type UserLoadingAction = {
  type: typeof USER_LOADING;
};

export type AuthActions =
  | AuthErrorAction
  | ImpersonateClearAction
  | ImpersonateLoadedAction
  | ImpersonateLoadingAction
  | LoginFailAction
  | LoginSuccessAction
  | LogoutSuccessAction
  | RegisterFailAction
  | RegisterSuccessAction
  | UserLoadedAction
  | UserLoadingAction;

/* Entity state */

export type SingleEntityState<T extends BasicEntity> = {
  list: T[];
  selected: T[];
  singleSelected: T | null;
  loading: boolean;
  notificationMessage: string;
};

export type EntitiesState = {
  usuario: SingleEntityState<User>;
  tipoUsuario: SingleEntityState<TipoUsuario>;
  estadoDocumento: SingleEntityState<EstadoDocumento>;
  sku: SingleEntityState<Sku>;
  skuFactor: SingleEntityState<SkuFactor>;
  perfilEnvejecimiento: SingleEntityState<PerfilEnvejecimiento>;
  diasVenta: SingleEntityState<DiasVenta>;
  centroInventario: SingleEntityState<CentroInventario>;
  zonasOperador: SingleEntityState<ZonasOperador>;
  activo: SingleEntityState<Activo>;
  tipoActivo: SingleEntityState<TipoActivo>;
  nomenclatura: SingleEntityState<Nomenclatura>;
  relacionEnvaseCasillero: SingleEntityState<RelacionEnvaseCasillero>;
  comentario: SingleEntityState<Comentario>;
  materialNomenclatura: SingleEntityState<MaterialNomenclatura>;
  reporteZonaCategoriaSKU: SingleEntityState<ReporteZonaCategoriaSKU>;
  reporteVencimiento: SingleEntityState<ReporteVencimiento>;
  reporteInventarioCriticidad: SingleEntityState<ReporteInventarioCriticidad>;
  documentoCriticidad: SingleEntityState<Documento>;
  documentoEnvases: SingleEntityState<Documento>;
  documentoPallet: SingleEntityState<Documento>;
  inventarioCriticidad: SingleEntityState<Inventario>;
  inventarioEnvases: SingleEntityState<Inventario>;
  inventarioPallet: SingleEntityState<Inventario>;
  conteoCriticidadLog: SingleEntityState<ConteoCriticidadLog>;
  inconsistenciaFechaLog: SingleEntityState<InconsistenciaFechaLog>;
  usuarioTransportista: SingleEntityState<UsuarioTransportista>;
  transportista: SingleEntityState<Transportista>;
  tipoCausal: SingleEntityState<TipoCausal>;
  ocaMermaIngresada: SingleEntityState<OCA>;
  ocaFaltanteIngresada: SingleEntityState<OCA>;
  ocaMermaObjetadaOrigen: SingleEntityState<OCA>;
  ocaFaltanteObjetadaOrigen: SingleEntityState<OCA>;
  ocaMermaObjetadaTransportista: SingleEntityState<OCA>;
  ocaFaltanteObjetadaTransportista: SingleEntityState<OCA>;
  ocaMermaCobrable: SingleEntityState<OCA>;
  ocaFaltanteCobrable: SingleEntityState<OCA>;
  ocaMermaEnDiscusion: SingleEntityState<OCA>;
  ocaFaltanteEnDiscusion: SingleEntityState<OCA>;
  cobro: SingleEntityState<Cobro>;
  ocaMermaCerrada: SingleEntityState<OCA>;
  ocaFaltanteCerrada: SingleEntityState<OCA>;
  ocaMermaExpirada: SingleEntityState<OCA>;
  ocaFaltanteExpirada: SingleEntityState<OCA>;
  cobroDashboard: SingleEntityState<Dashboard>;
  itemSociedad: SingleEntityState<ItemSociedad>;
  item: SingleEntityState<Item>;
  sociedad: SingleEntityState<Sociedad>;
};

export const GET_ENTITY = 'GET_ENTITY';
export const VOID_ENTITY = 'VOID_ENTITY';
export const ADD_ENTITY = 'ADD_ENTITY';
export const DELETE_ENTITY = 'DELETE_ENTITY';
export const EDIT_ENTITY = 'EDIT_ENTITY';
export const SELECTED_ENTITY = 'SELECTED_ENTITY';
export const SINGLE_SELECTED_ENTITY = 'SINGLE_SELECTED_ENTITY';
export const LOADING_ENTITY = 'LOADING_ENTITY';
export const NOTIFY_MESSAGE_ENTITY = 'NOTIFY_MESSAGE_ENTITY';
export const REFRESH_ENTITY = 'REFRESH_ENTITY';

// These two are not mapped to any action but rather used within the actions themselves
export const ADD_FAIL_ENTITY = 'ADD_FAIL_ENTITY';
export const DELETE_FAIL_ENTITY = 'DELETE_FAIL_ENTITY';
export const EDIT_FAIL_ENTITY = 'EDIT_FAIL_ENTITY';

type GetEntityAction = {
  type: typeof GET_ENTITY;
  payload: BasicEntity[];
  entityName: WebEntityName;
};

type AddEntityAction = {
  type: typeof ADD_ENTITY;
  payload: BasicEntity[];
  entityName: WebEntityName;
};

type DeleteEntityAction = {
  type: typeof DELETE_ENTITY;
  payload: string[];
  entityName: WebEntityName;
};

type EditEntityAction<T extends BasicEntity> = {
  type: typeof EDIT_ENTITY;
  payload: T;
  entityName: WebEntityName;
};

type SelectedEntityAction = {
  type: typeof SELECTED_ENTITY;
  payload: BasicEntity[];
  entityName: WebEntityName;
};

type SingleSelectedEntityAction = {
  type: typeof SINGLE_SELECTED_ENTITY;
  payload: BasicEntity;
  entityName: WebEntityName;
};

type notifyMessageEntityAction = {
  type: typeof NOTIFY_MESSAGE_ENTITY;
  payload: string;
  entityName: WebEntityName;
};

type LoadingEntityAction = {
  type: typeof LOADING_ENTITY;
  payload: boolean;
  entityName: WebEntityName;
};

type refreshEntityAction = {
  type: typeof REFRESH_ENTITY;
  payload: null;
  entityName: WebEntityName;
};

export type EntitiesActions<T extends BasicEntity> =
  | GetEntityAction
  | AddEntityAction
  | DeleteEntityAction
  | EditEntityAction<T>
  | SelectedEntityAction
  | SingleSelectedEntityAction
  | notifyMessageEntityAction
  | LoadingEntityAction
  | refreshEntityAction;

/* Error state */

export type ErrorState = {
  msg: any;
  status: number | null;
  id: string | null;
};

export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

type GetErrorsAction = {
  type: typeof GET_ERRORS;
  payload: {
    msg: any;
    status: number;
    id: string | null;
  };
};

type ClearErrorsAction = {
  type: typeof CLEAR_ERRORS;
};

export type ErrorActions = GetErrorsAction | ClearErrorsAction;
